@import "../../assets/scss/colors";

.MuiList-root {
  .MuiMenuItem-root {
    min-height: 31px;
  }
}

.select {
  .MuiInput-underline {
    width: 100%;

    &:before, &:after {
      display: none !important;
    }
  }

  .MuiInput-input {
    color: #888;
    padding: 10px 12px 0 12px;
  }
}

.error-text {
  color: #ab4343;
}

select.profileform {
  //font-family: "PT Sans",Helvetica,Arial,sans-serif;
  font-size: 13px;
  line-height: 1.5384;
  //color: #888;
  display: block;
  height: unset;
  //width: calc(100% - 24px);
  //padding: 0;
  //background-color: transparent;
  //background-image: none;
  //border: none;
  //border-bottom: 1px solid #888;
  //border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.jez-selection {
  position: relative;
  margin-top: 16px;

  .jez-selection__control--is-focused {
    z-index: 9000;
  }

  .jez-selection__control {
    position: relative;
    display: block;
    width: calc(100% - 0px);
    padding: 3px 12px;
    font-size: 13px;
    line-height: 1.4384;
    background: rgba(0,0,0,0.4);
    background-color: rgba(0,0,0,0.4) !important;
    box-sizing: border-box;
    background-image: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    color: $gold-color;
    border: 1px solid #444;
    border-radius: 2px;
    min-height: 31px;
    cursor: pointer;
    margin: 0 0 5px 0;

    .jez-selection__value-container {
      padding: 0;
      overflow: visible;
    }

    &:hover {
      border: 1px solid #b4ab6e;
    }

    .jez-selection__indicators {
      position: absolute;
      top: -2px;
      right: 0;


      .jez-selection__indicator-separator {
        display: none;
      }
    }

    .jez-selection__single-value {
      color: $gold-color;
    }

    .jez-selection__multi-value {
      background: $gold-color;
      border: 1px solid darken($gold-color, 60%);
      color: #fff;
      border-radius: 3px;

      .jez-selection__multi-value__label {
        color: #fff;
      }
    }
  }
}

.jez-selection__menu {
  z-index: 90;
  position: relative !important;
  display: block;
  border: 1px solid #444;
  background: #101010;
  background-color:  #101010 !important;
  border-radius: 2px;

  .jez-selection__option {
    z-index: 90;
    cursor: pointer;
    color: #BBB;
    background-color: #101010;
    display: block;
    position: relative;
  }

  //.jez-selection__option:nth-child(even) {
  //  background: #343434;
  //}

  .jez-selection__option:hover {
    background: $gold-color;
    color: #fff;
  }

  .jez-selection__group-heading {
    color: #fff;
    background: #444;
    z-index: 90;
    line-height: 20px;
    padding: 3px 10px;
  }
}

.select {
  width: 100%;

  label {
    color: #fff;
    left: 12px;
    z-index: 1;
    pointer-events: none;
    transform: scale(0.75);
  }
}
//
//
//  .MuiSelect-select {
//    display: block;
//    width: calc(100% - 0px);
//    height: 33px;
//    padding: 6px 12px;
//    font-size: 13px;
//    line-height: 1.5384;
//    background-color: #000 !important;
//    box-sizing: border-box;
//    background-image: none;
//    border-radius: 0;
//    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
//    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
//    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
//    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
//    color: #888;
//    border: 1px solid #222;
//
//    &.select-placeholder {
//      .MuiSelect-select {
//        color: #444 !important;
//      }
//    }
//
//    &:focus {
//      border-color: $gold-color;
//      outline: 0;
//      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(128, 113, 84, 0.6);
//      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(128, 113, 84, 0.6);
//    }
//  }
//
//  &.margin {
//    margin-top: 12px;
//  }
//
//  &.error {
//    border-color: #ab4343;
//  }
//}
//
//.select {
//  label {
//    transform: none;
//  }
//}
//
//.simple-select {
//  margin-top: 16px;
//
//  .value-wrapper {
//    color: #888 !important;
//    background: transparent !important;
//    padding: 0 !important;
//  }
//}
//
//.multi-select {
//  margin-top: 16px;
//}
//
//.react-selectize {
//  .react-selectize-control {
//    position: relative;
//
//    .react-selectize-toggle-button-container, .react-selectize-reset-button-container {
//      position: absolute;
//      top: 10px;
//      right: 10px;
//      color: #444;
//      fill: #444;
//    }
//
//    .react-selectize-reset-button-container {
//      right: 30px;
//    }
//
//    &:hover {
//      .react-selectize-toggle-button-container, .react-selectize-reset-button-container {
//        color: $gold-color;
//        fill: $gold-color;
//      }
//    }
//
//    .react-selectize-search-field-and-selected-values {
//      width: calc(100% - 0px);
//      padding: 1px 12px 7px 12px;
//      font-size: 13px;
//      min-height: 33px;
//      line-height: 1.5384;
//      border: 1px solid #000;
//      background: rgba(0, 0, 0, 0.4) !important;
//      background-color: rgba(0, 0, 0, 0.4) !important;
//      border-radius: 2px;
//      box-sizing: border-box;
//
//      input {
//        border: 0 !important;
//        background: transparent;
//        color: #fff;
//        outline: none;
//      }
//
//      &:not(:focus) {
//        border: 1px solid #444;
//      }
//
//      &:hover {
//        border: 1px solid #b4ab6e;
//      }
//
//      .value-wrapper {
//        display: inline-block;
//        padding: 3px 10px;
//        background: $gold-color;
//        color: #fff;
//        border-radius: 3px;
//        margin: 5px 5px 0 0;
//      }
//    }
//  }
//
//  .dropdown-menu {
//    position: absolute;
//    top: calc(100% - 11px);
//    left: 15px;
//    right: 15px;
//    z-index: 600;
//    border: 1px solid #444;
//    background: rgba(0, 0, 0, 0.6);
//    background-color: rgba(0, 0, 0, 0.6) !important;
//    border-radius: 2px;
//    max-height: 300px;
//    overflow: auto;
//
//    .no-results-found {
//      color: #fff;
//      line-height: 20px;
//      padding: 3px 10px;
//      cursor: default;
//    }
//
//    .simple-group-title {
//      color: #fff;
//      background: #444;
//      line-height: 20px;
//      padding: 3px 10px;
//    }
//
//    .option-wrapper {
//      color: #fff;
//      line-height: 20px;
//      padding: 3px 10px;
//      cursor: pointer;
//    }
//
//    .option-wrapper:nth-child(even) {
//      background: rgba(180, 171, 110, 0.2)
//    }
//
//    .option-wrapper:hover {
//      background: rgba(180, 171, 110, 1);
//    }
//
//    .groups {
//      .option-wrapper {
//        padding: 3px 20px;
//      }
//    }
//  }
//
//}