@import "./../../assets/scss/colors";

ul {
  li.has-sub-navigation {
    position: relative;
    background: transparent;

    .sub-menu-header {
      display: block;
      padding: 5px 10px;
      text-transform: uppercase;
      line-height: 19px;
      color: #fff;
      text-decoration: none;
      margin: 19px 0 -16px 0;
      background: #000;
      position: relative;
      z-index: 500;
      border: 1px solid transparent;
      cursor: default;
      border-radius: 3px;
      transition: margin .25s linear;
    }

    ul.navigation-nav-navigation {
      min-width: 155px;
      position: absolute;
      left: -80000px;
      opacity: 0;
      transition: opacity .25s linear;
      transform: translateX(-50%);
      display: block;
      border: 1px solid $gold-color;
      background: $backgroundColorRGBA;
      border-radius: 3px;
      padding: 15px 0 0 !important;

      li {
        position: relative;
        width: 100%;
        text-align: center;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 10px;
          right: 10px;
          height: 1px;
          background: rgba(180, 171, 110, 0.2);
        }

        a {
          padding: 10px 15px !important;
        }
      }

      li:first-child {
        &:before {
          display: none;
        }
      }
    }

    &:hover {
      z-index: 8000;

      .sub-menu-header {
        border: 1px solid $gold-color;
        margin: 29px 0 -16px 0;
      }

      ul.navigation-nav-navigation {
        left: 50%;
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: 768px) {
    li.has-sub-navigation {
      z-index: 8000;
      background: #000000;
      ul.navigation-nav-navigation {
        padding: 20px 0 0!important;
        position: relative;
        left: unset;
        opacity: 1;
        -webkit-transform: unset;
        transform: unset;
        border: none;

        li {
          padding-left: 15px;
          text-align: left;
          &:before{
            display: none;
          }
        }
      }
      .sub-menu-header{
        border: none;
        margin: 29px 0 -16px 0;
      }
      &:hover{
        .sub-menu-header{
          border: none;
          margin: 29px 0 -16px 0;
        }
        ul.navigation-nav-navigation {
          left: unset !important;
          opacity: 1;
        }
      }
    }
  }
}

.sliderButton {
  background: transparent;
  background-color: transparent;
  border: 1px solid #b4ab6e;
  border-radius: 0;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Oranienbaum';
  font-size: 17px;
  letter-spacing: 10px;
  line-height: 1.33;
  margin-top: 30px;
  padding: 10px 20px 10px 30px;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  text-transform: uppercase;

  &.highlighted {
    color: #000;
    background: $gold-color;
    border: 1px solid $dark-gold-color;
    border-radius: 5px;
    font-weight: bold;
  }
}
.sliderButton2 {
  border: 3px solid #b4ab6e;
  font-size: 20px;
}
.mapButton {
  background: transparent;
  background-color: transparent;
  border: 1px solid $gold-color;
  border-radius: 0;
  color: #ffffff;
  width: 48px;
  cursor: pointer;
  font-family: '"PT Sans", Helvetica, Arial, sans-serif';
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  line-height: 1.33;
  margin-top: 0px;
  text-align: center;
  text-transform: uppercase;
}