@import "../../assets/scss/colors";

.relative-positioning {
  position: relative;
}

.gridwrap{
  height: 280px;
  transition: all 0.5s;
  position: relative;
  background-color: $gold-color;
  .gridimg {
    filter: grayscale(100%);
    background-size: cover;
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    opacity: 1;
    transition: all 0.5s;
    background-position:  center center;
  }
  .tooltiptext{
    opacity: 0;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0 , 0%) 0%,rgba(0, 0, 0 , 70%) 60%);;
    color: #fff;
    text-align: center;
    font-family: 'Oranienbaum', Georgia, 'Times New Roman', Times, serif;;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    text-transform: uppercase;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 0;
    transition: all 0.5s;
  }

  &:hover {
    .gridimg{
      filter: grayscale(0%);
    }
    .tooltiptext{
      opacity: 1;
    }
  }
}

@media(max-width: 1024px) {
  .gridwrap{
    .tooltiptext{
      opacity: 1;
    }
  }
}