@import "../../assets/scss/colors";

.spacer {
    border-bottom: 1px solid #333;
    font-size: 30px;
    position: relative;
    color: $gold-color;
    margin:1.2em auto;
    max-width: 970px;

    svg, img {
        position: absolute;
        left:50%;
        transform: translateX(-50%);
        top: -15px;
    }
}
