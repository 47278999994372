@import "../../assets/scss/colors";

.page-info-container {
  text-align: center;
  padding: 20px 0;
  font-size: 20px;
  border: 1px solid #000;
  margin: 15px 0;
}

.page-info-type-default, .page-info-type-warning {
  border-color: $gold-color;
  color: $gold-color;
}