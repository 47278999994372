@import "./../../assets/scss/colors";

.info-box {
  margin: 5px 0;
  padding: 15px 30px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #000;
  color: #fff;

  &.general {
    border-color: $gold-color;
    color: $gold-color;
  }
}