@import "../../../assets/scss/colors";

.address-data {
  .contact-route {
    margin: 5px 0 0 0;
    background: $gold-color;
    color: #fff;
    font-size: 14px;
    display: inline-block;
    border: 1px solid $dark-gold-color;

    &:hover {
      background: $dark-gold-color;
      border: 1px solid $gold-color;
    }
  }
}

@media (max-width: 468px) {
  .contact-address-column {
    text-align: center;

    .address-data {
      display: inline-block;
    }
  }
}