.slide {
	display: flex;
	width: 100%;
    height: 100%;

    div {
        background-size: cover;
        width: 100%;
        background-position: center center;
        height: 100%;
        background-attachment: fixed;
    }
}
