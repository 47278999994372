@import "../../assets/scss/colors";

.jeztexteditor{
  .ck.ck-toolbar, .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners{
    background: rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.4);
    color: #888;
    border: 1px solid #444;
    border-radius: 0;
  }
  .ck.ck-editor__main>.ck-editor__editable, .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners, .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    background: rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.4);
    color: $gold-color;
    border-color: #444;
    border-radius: 0;
    min-height: 250px;
    p{
      color: $gold-color;
    }
  }
  .ck.ck-toolbar .ck.ck-toolbar__separator{
    background: #888;
    background-color: #888;
  }
  .ck.ck-reset_all, .ck.ck-reset_all *{
    color: #888;;
  }
  .ck.ck-button.ck-on, a.ck.ck-button.ck-on, .ck.ck-list__item .ck-button.ck-on, .ck.ck-button, a.ck.ck-button {
    background: none;
    background-color: unset;
  }

  .ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover{
    background: #202020;
  }

  .ck.ck-button.ck-on:not(.ck-disabled):active, a.ck.ck-button.ck-on:not(.ck-disabled):active, .ck.ck-button.ck-on:not(.ck-disabled):hover, a.ck.ck-button.ck-on:not(.ck-disabled):hover, .ck.ck-list__item .ck-button:hover:not(.ck-disabled), .ck.ck-list__item .ck-button.ck-on:hover:not(.ck-disabled){
    background: #202020;
  }
  .ck.ck-dropdown__panel{
    background: rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.4);
    border-color: #888888;
    .ck.ck-input-text{
      display: block;
      width: calc(100% - 24px);
      height: 33px;
      padding: 6px 12px;
      font-size: 13px;
      line-height: 1.5384;
      color: #888;
      background-image: none;
      border: 1px solid #222;
      border-radius: 0;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }
    .ck.ck-input-text:focus {
      outline: none;
      border: 1px solid #444;
      box-shadow: none;
    }
  }
  [dir=ltr] .ck.ck-labeled-field-view>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label{
    background: rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.4);
    color: #888;
  }
  .ck.ck-list__item .ck-button.ck-on{
    color: #888;
  }
  .ck.ck-button .ck-button__label, a.ck.ck-button .ck-button__label{
    color: #888;
  }
}

.ck-body-wrapper{
  .ck.ck-balloon-panel, .ck .ck-balloon-rotator__navigation, .ck-balloon-rotator__content *{
    background: rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.4);
    border-color: #888888;
  }
  .ck.ck-balloon-panel[class*=arrow_n]:after {
    border-bottom-color: #000;
    margin-top: var(--ck-balloon-arrow-offset);
  }
  .ck.ck-balloon-panel[class*=arrow_n]:before {
    border-bottom-color: #888;
  }
  .ck.ck-input-text{
    display: block;
    width: calc(100% - 24px);
    height: 33px;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.5384;
    color: #888;
    background: rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
    border: 1px solid #444;
    border-radius: 0;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  }
  .ck.ck-input-text:focus {
    outline: none;
    border: 1px solid #444;
    box-shadow: none;
  }
  .ck.ck-labeled-field-view>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label, [dir=ltr] .ck.ck-labeled-field-view.ck-labeled-field-view_empty:not(.ck-labeled-field-view_focused):not(.ck-labeled-field-view_placeholder)>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-labe{
    background: rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.4);
    color: #888;
  }
  .ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover{
    background: #202020;
  }
  .ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label{
    color: $gold-color;
  }
  .ck.ck-icon, .ck.ck-icon *{
    color: #888;
  }
}