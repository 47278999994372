@import "./../../assets/scss/colors";

@mixin callingAction() {
  .calling-actions {
    .call-end {
      display: inline-block;
      color: #fff;
      border-radius: 50%;
      padding: 5px;
      width: 25px;
      height: 25px;
      text-align: center;
      position: relative;
      margin: 0;
      cursor: pointer;
      background: #AF4C50;

      .icon {
        line-height: 50px;
        font-size: 25px;
      }

      &:hover:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        animation: rejectCall 4s linear infinite both;
      }

      &:hover .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        border-radius: 50%;
      }

      &:hover .wave {
        opacity: .4;
        position: absolute;
        top: 25%;
        left: -15%;
        background: white;
        width: 130%;
        height: 130%;
        transform-origin: 50% 48%;
        border-radius: 45%;
        animation: drift 3000ms infinite linear;
        opacity: 0.2;

        &.two {
          animation: drift 7000ms infinite linear;
          top: 30%;
          opacity: 0.1;
        }

        &.three {
          animation: drift 5000ms infinite linear;
          opacity: 0.05;
          top: 35%;
        }

      }
    }
  }
}

.initiate-call {
  position: fixed;
  left: -100px;
  bottom: 25px;
  font-size: 26px;
  cursor: pointer;

  transition: all .5s ease;
  -moz-transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  color: #fff;
  text-decoration: none;
  background: transparent;

  svg {
    font-size: 26px;
    background: #464646;
    border-radius: 50%;
    padding: 5px;
  }

  &:hover {
    color: $gold-color;
  }
}

.video-call-container {
  .hidden {
    position: absolute;
    left: -50000px;
  }

  .data-list {
    display: block;
    width: 100%;
    max-width: 210px;
    margin: 0 auto 30px auto;
    list-style-type: none;

    li {
      .data-label, .data-value {
        display: inline-block;
        color: #fff;
        width: 49%;
      }
    }
  }

  .video-call-connection {
    .video-container {
      display: inline-block;
      width: calc(68% - 10px);
      margin: 0 10px 0 0;

      .video-stream {
        position: relative;
        height: 468px;
        border: 1px solid $gold-color;
        vertical-align: top;
        overflow: hidden;

        .anti-click-overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      .calling-actions {
        .call-end {
          display: inline-block;
          color: #fff;
          border-radius: 50%;
          padding: 5px;
          width: 25px;
          height: 25px;
          text-align: center;
          position: relative;
          margin: 10px 0 0 0;
          cursor: pointer;
          background: #AF4C50;

          .icon {
            line-height: 50px;
            font-size: 25px;
          }

          &:hover:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 50%;
            animation: rejectCall 4s linear infinite both;
          }

          &:hover .cover {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            overflow: hidden;
            border-radius: 50%;
          }

          &:hover .wave {
            opacity: .4;
            position: absolute;
            top: 25%;
            left: -15%;
            background: white;
            width: 130%;
            height: 130%;
            transform-origin: 50% 48%;
            border-radius: 45%;
            animation: drift 3000ms infinite linear;
            opacity: 0.2;

            &.two {
              animation: drift 7000ms infinite linear;
              top: 30%;
              opacity: 0.1;
            }

            &.three {
              animation: drift 5000ms infinite linear;
              opacity: 0.05;
              top: 35%;
            }

          }
        }
      }
    }

    &.phone-only {
      .video-container {
        display: none;
      }

      .video-chat-container {
        width: 100%;

        .video-chat-input-container {
          width: 100%;
          position: relative;

          .calling-actions {
            position: absolute;
            left: 5px;
            bottom: -2px;

            .call-end {
              display: inline-block;
              color: #fff;
              border-radius: 50%;
              padding: 5px;
              width: 25px;
              height: 25px;
              text-align: center;
              position: relative;
              margin: 10px 0 0 0;
              cursor: pointer;
              background: #AF4C50;

              .icon {
                line-height: 50px;
                font-size: 25px;
              }

              &:hover:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 50%;
                animation: rejectCall 4s linear infinite both;
              }

              &:hover .cover {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                overflow: hidden;
                border-radius: 50%;
              }

              &:hover .wave {
                opacity: .4;
                position: absolute;
                top: 25%;
                left: -15%;
                background: white;
                width: 130%;
                height: 130%;
                transform-origin: 50% 48%;
                border-radius: 45%;
                animation: drift 3000ms infinite linear;
                opacity: 0.2;

                &.two {
                  animation: drift 7000ms infinite linear;
                  top: 30%;
                  opacity: 0.1;
                }

                &.three {
                  animation: drift 5000ms infinite linear;
                  opacity: 0.05;
                  top: 35%;
                }

              }
            }
          }
        }
      }
    }
  }

  .call-user {
    text-align: center;
    color: #fff;
    font-size: 20px;
    margin: 20px 0;
    position: relative;

    @include callingAction();

    .calling-actions {
      position: absolute;
      top: -10px;
      left: 0;
    }
  }

  .incoming-video-call {
    .call-action-container {
      text-align: center;
    }

    .image-container {
      img {
        display: block;
        width: 100px;
        height: 100px;
        margin: 30px auto;
        border-radius: 50%;
        border: 3px solid $gold-color;
      }

      .call-animation {
        background: #fff;
        width: 100px;
        height: 100px;
        position: relative;
        border-radius: 100%;
        border: solid 3px $gold-color;
        animation: play 2s ease infinite;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        margin: 30px auto;

        img {
          margin: 0;
          border: 0;
        }

      }
    }

    .phone, .reject-phone {
      display: inline-block;
      color: #fff;
      border-radius: 50%;
      padding: 5px;
      width: 50px;
      height: 50px;
      text-align: center;
      position: relative;
      margin: 15px 30px;
      cursor: pointer;

      .icon {
        line-height: 50px;
        font-size: 2.25em;
        margin: 10px 0 0 0;
      }

      &:hover:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        animation: rejectCall 4s linear infinite both;
      }

      &:hover .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        border-radius: 50%;
      }

      &:hover .wave {
        opacity: .4;
        position: absolute;
        top: 25%;
        left: -15%;
        background: white;
        width: 130%;
        height: 130%;
        transform-origin: 50% 48%;
        border-radius: 45%;
        animation: drift 3000ms infinite linear;
        opacity: 0.2;

        &.two {
          animation: drift 7000ms infinite linear;
          top: 30%;
          opacity: 0.1;
        }

        &.three {
          animation: drift 5000ms infinite linear;
          opacity: 0.05;
          top: 35%;
        }
      }
    }

    .reject-phone {
      background: #AF4C50;
    }

    .phone {
      background: #4CAF50;

      &:hover:before {
        animation: acceptCall 4s linear infinite both;
      }

      &:after,
      &:before {
        position: absolute;
        content: '';
        opacity: 0;
        border-right: 2px solid #ffffff;
        width: 15px;
        height: 15px;
        left: 40%;
        top: 28%;
        border-radius: 50%;
        transform: rotate(-40deg);
        animation: fadeInOne 2s infinite both;
      }

      &:before {
        width: 20px;
        height: 20px;
        left: 40%;
        top: 20%;
        animation: fadeInTwo 2s infinite both;
      }
    }

    .moderated-host {
      position: absolute;
      border: 1px solid $gold-color;
      top: 25px;
      width: 320px;
      left: -330px;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      background: rgba(0, 0, 0, 0.9);
      border-radius: 3px;

      .call-user-host, .call-user-host-data {
        text-align: center;
        color: $gold-color;
        font-size: 20px;
        margin: 20px 0;
      }

      .call-user-host-data {
        margin: 20px 0 5px 0;
      }
    }
  }
}

.video-call-exception-modal {
  position: absolute;
  width: 500px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  border: 1px solid $gold-color;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 15px 25px;
  color: $gold-color;
  text-align: center;

  .headline {
    margin: 0 0 15px 0;
  }

  ul {
    list-style-type: none;

    li {
      font-weight: bold;
      margin: 0 0 15px 0;
    }
  }

  .video-call-help {
    margin: 30px 0 15px 0;

    .MuiAccordion-root {
      background: rgba(0, 0, 0, 0.9);
      border-radius: 3px;
      border: 1px solid $gold-color;

      .MuiAccordionSummary-root {
        color: $gold-color;
        min-height: 20px;
        height: 30px;

        .MuiIconButton-root {
          color: $gold-color;
        }

        .MuiAccordionSummary-content {
          margin: 6px 0;
        }
      }

      .MuiCollapse-container {
        color: $gold-color;
        text-align: justify;

        ol {
          li {
            margin: 0 0 10px 0;
          }
        }

        .helper-image-container {
          margin: 5px 0;
          width: 100%;
          max-width: 300px;
          overflow: hidden;
          border-radius: 3px;
          border: 1px solid $gold-color;

          img {
            width: 100%;
            display: flex;
          }
        }
      }
    }
  }
}

@keyframes fadeInOne {
  45% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes fadeInTwo {
  55% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes rejectCall {
  0% {
    padding: 0em;
    background-color: rgba(175, 76, 80, 0);
  }
  25% {
    padding: 1em;
    background-color: rgba(175, 76, 80, 0.5);
    transform: translateX(-1em) translateY(-1em);
  }
  26%,
  100% {
    padding: 0;
    opacity: 0;
  }
}

@keyframes acceptCall {
  0% {
    padding: 0em;
    background-color: rgba(76, 175, 80, 0);
  }
  25% {
    padding: 1em;
    background-color: rgba(76, 175, 80, 0.5);
    transform: translateX(-1em) translateY(-1em);
  }
  26%,
  100% {
    padding: 0;
    opacity: 0;
  }
}

@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes play {

  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 5px rgba(180, 171, 110, 0.4);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(180, 171, 110, 0.4), 0 0 0 20px rgba(180, 171, 110, 0.2);
  }
  25% {
    box-shadow: 0 0 0 15px rgba(180, 171, 110, 0.4), 0 0 0 30px rgba(180, 171, 110, 0.2);
  }
}

@media (max-width: 768px) {
  .mobile-container {
    border-radius: 3px;
    border: 1px solid $gold-color;
    position: fixed;
    top: 90px;
    left: 10px;
    right: 10px;
    z-index: 80000;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background: rgba(0,0,0,0.9);

    .headline {
      background: $gold-color;
      color: #fff;
      padding: 3px 10px;
      font-size: 16px;
    }

    .content {
      padding: 10px;
    }

    .video-chat-container {
      height: 390px;
      .video-chat-messages {
        height: 230px;
      }
    }
  }

  .out-off-screen {
    .mobile-container {
      left: -9000px;
      right: auto;
    }
  }

  .video-call-exception-modal {
    width: auto;
    left: 10px;
    right: 10px;
    top: 90px;
    bottom: 10px;
    overflow: scroll;
    transform: none;

      .video-call-help {
        .MuiAccordion-root {
          .MuiAccordionSummary-root {
            height: 50px;
          }
        }
      }
  }
}

//@media (max-width: 768px) {
//  .video-call-connection {
//    .video-container {
//      display: inline-block;
//      width: calc(68% - 10px);
//      margin: 0 10px 0 0;
//
//      .video-stream {
//        position: relative;
//        height: 468px;
//        border: 1px solid $gold-color;
//        vertical-align: top;
//        overflow: hidden;
//
//        .anti-click-overlay {
//          position: absolute;
//          top: 0;
//          right: 0;
//          bottom: 0;
//          left: 0;
//        }
//      }
//    }
//  }
//}