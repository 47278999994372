@import "../../assets/scss/colors";

.onlyMobile{
  display: none;
  @media only screen and (max-width: 959px) {
    display: block;
  }
}

.maxWidth1170 {
  max-width: 1170px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;

  .spacer {
    max-width: 100%;
  }
}

.text{
  p{
    margin: 0;
  }
}

h4.profileform{
  margin-top: 10px;
}

.spacer{
  margin: 20px auto 30px auto;
}

.image-gallery .image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(70vH - 80px);
}

.profileimg {
  display: block;
  max-width: 100%;
  height: 748px;
  @media only screen and (max-width: 600px) {
    height: 550px;
  }
  background-size: cover;
  background-position: center center;
}

.listStyleNone {
  list-style: none;
  @media only screen and (max-width: 959px) {
    padding: 0;
  }
}

.strong {
  color: #ffffff;
  font-weight: normal;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.5384;
}

.text {
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.5384;
  color: #ffffff;
}
div.text {
  font-size: 16px;
  font-weight: lighter;
}

.services {
  margin: 15px 0 0 0;
  display: block;

  strong {
    display: block;
  }

  &:first-child {
    margin: 0;
  }
}

.login-required-modal {
  position: absolute;
  width: 500px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  border: 1px solid $gold-color;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 15px 25px;
  color: $gold-color;
  text-align: center;

  .headline {
    margin: 0 0 15px 0;
  }

  .modal-action-bar {
    padding: 25px 0 0 0;
  }

  button, .btn-link {
    margin: 0 5px;
  }
}

@media (max-width: 768px) {
  .login-required-modal  {
    width: auto;
    left: 10px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    overflow: scroll;

    button, a {
      width: 100%;
      box-sizing: border-box;
      margin: 15px 0 0 0 !important;
    }
  }
}