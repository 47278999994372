@import "../../assets/scss/colors";

.external-menu-link {
    display: inline-block;
}

.overlay-wrap {
    position: relative;
    .overlay-container {
        position: absolute;
        width: 100%;
        left:0;
        z-index:100;

        h3 {
            margin-left: 120px;
        }
        @media screen and (max-width: 620px){
            .newsButton{
                margin-bottom: 20px;
                position: relative;
                display: inline-block;
            }
        }
    }
}

.golden-element-container {
    .golden-element {
        color: $gold-color;
    }
}