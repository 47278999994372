.totopbutton{
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1234;
  cursor: pointer;
  svg{
    width: 35px;
    height: 35px;
  }
}