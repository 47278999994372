@import "./../../assets/scss/colors";

.video-chat-container {
  position: relative;
  vertical-align: top;
  display: inline-block;
  width: 30%;
  height: 468px;
  border: 1px solid $gold-color;

  .video-chat-messages {
    height: 310px;
    max-height: 310px;
    overflow: auto;
    margin: 2px 3px 0 0;
    border-bottom: 1px solid $gold-color;

    .my-message, .other-message {
      div {
        position: relative;
        color: #fff;
        padding: 17px 15px 8px 10px;
        border: 1px solid $gold-color;
        border-radius: 3px;
        display: inline-block;
        margin: 5px 10px;
        text-align: left;
        min-width: 100px;

        .time {
          position: absolute;
          font-size: 10px;
          right: 5px;
          top: 5px;
          color: $gold-color
        }
      }
    }

    .my-message {
      text-align: right;
    }
  }

  .video-chat-input-container {
    position: absolute;
    bottom: 0;

    textarea {
      background: transparent;
      margin: 5px;
      border: 1px solid $gold-color;
      width: 100%;
      color: #fff;
      max-width: calc(100% - 10px);
      box-sizing: border-box;
    }

    button {
      font-size: 14px;
      border: 0;
      color: #fff;
      margin: 5px auto;
    }
  }
}