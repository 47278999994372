@import "../../assets/scss/colors";

.homelocationgrid-container{
  margin-bottom: 20px;
  .locationgridwrap{
    height: 280px;
    position: relative;
    &.firstimage{
      height: 568px;
    }
    .locationgridimg{
      filter: grayscale(100%);
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 1;
      transition: all 0.5s;
      background-position: center center;
    }
    .tooltiptext{
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      color: #fff;
      margin: 0;
      font-size: 21px;
      font-family: 'Oranienbaum', Georgia, 'Times New Roman', Times, serif;
      font-weight: normal;
      line-height: 1.1;
      text-transform: uppercase;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(32, 32, 32, 0) 100%);
      display: block;
      padding: 10px;
    }
    &:hover{
      .locationgridimg{
        filter: grayscale(10%);
      }
    }
  }
}

@media (max-width: 468px) {
  .homelocationgrid-container {
    .locationgridwrap {
      .tooltiptext{
        bottom: initial;
        top: 0;
      }
    }
  }
}