@import "../../assets/scss/colors";
.list-item-image-container {
  position: relative;
  cursor: pointer;

  .gallery-play-video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: $gold-color;

  }
}