@import "../../assets/scss/colors";

button, .fileContainer button, .btn-link, a.btn {
	border: 0;
	padding: 0.5em 1em;
	border-radius: 2px;

	&:disabled {
		opacity: 0.2;
	}

	outline: none;
	&.chooseFileButton:hover{
		background: none;
	}
	&.btn {
		display: inline-block;
		margin-bottom: 0;
		font-weight: normal;
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
		background-image: none;
		border: 0;
		white-space: nowrap;
		padding: 0.5em 1em;
		font-size: 13px;
		line-height: 1.5384;
		border-radius: 2px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        text-transform: uppercase;
	}

	&.error {
		background: $dark-red;
	}

	&.outlined {
		border-radius: 2px;
		color: $gold-color;
		border: 1px solid $gold-color;
		background: transparent;

		&:hover, &.active {
			color: #fff;
			background: $gold-color;
		}
	}

	&.primary {
		font-weight: bold;
		padding: 0.7em 1.5em;
		background-image: -webkit-linear-gradient($gold-gradient);
		background-image: linear-gradient($gold-gradient);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff807154', endColorstr='#ff5b503c', GradientType=0);
		filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
		background-repeat: repeat-x;
		border-color: #554b38;
		text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
		font-size: 17px;
		line-height: 1.33;
		border-radius: 2px;
		color: #fff;
		background-color: $gold-color;

		&.secondary {
			font-weight: normal;
			text-transform: none;
		}

		&:hover {
			color: #fff;
            border-color: #4c4332;
            background-color: $dark-gold-color;
            background-position: 0 -15px;
		}
    }
	&.error {
		background-image: -webkit-linear-gradient( $red-gradient);
		background-image: linear-gradient( $red-gradient);
		background-color: $dark-red;

		&:hover {
			background-color: $dark-red-hover;
			background-position: 0 -15px;
		}
	}
    &.center {
        display: block;
        margin: 10px auto 0;
    }
    &.margin {
        margin-top: 12px;
    }

	&.small {
		padding: .25em .5em !important;
		line-height: 1;
	}

	&.full-width {
		width: 100%;
	}

	&:hover {
		text-decoration: none;
	}
}

a.btn {
	border: 0;
    padding: 0.5em 1em;
    border-radius: 2px;
	width: 100%;
	box-sizing: border-box;
}