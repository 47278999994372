@import "../../assets/scss/colors";

#video-embed-submit {
  float: right;
}

.image-upload-container {
  vertical-align: top;
  font-size: 0;

  * {
    font-size: 16px;
  }

  .video-player-element-bottom:hover {
    transition: bottom .5s linear;
    bottom: 55px
  }

  .video-player-element-top:hover {
    transition: bottom .5s linear;
    top: 46px
  }

  .play-video-icon {
    cursor: pointer;
    position: absolute;
    width: 100px;
    height: 100px;
    border: solid 2px $gold-color;
    border-radius: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .play-icon {
      color: $gold-color;
      position: absolute;
      top: 28px;
      left: 35px;
      font-size: 43px;
    }

    &:before {
      content: "";
      width: 34px;
      height: 53px;
      border-left: 2px solid $gold-color;
      border-radius: 5px;
      position: absolute;
      top: 23px;
      left: 37px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 27px;
      left: 19px;
      width: 42px;
      height: 42px;
      transform: rotate(45deg) skew(-5deg, -5deg);
      border-top: 2px solid $gold-color;
      border-right: 2px solid $gold-color;
      border-radius: 5px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .image-upload-input, .youtube-embed-input {
    display: inline-block;
    width: calc(50% - 10px);
    text-align: center;
    color: #888;
    cursor: pointer;
    margin: 5px 5px 15px 5px;
    padding: 30px 10px;
    border: 1px solid #888888;
    font-size: 12px;
    box-sizing: border-box;
    transition: none;

    &.fullwidth {
      width: 100%;
    }
  }

  .image-upload-context {
    position: relative;
    margin: 0 -5px;

    .video-embed-input-context {
      margin: 5px 5px 0 5px;
      display: block;
      border: 1px solid #b4ab6e;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      padding: 5px 15px 10px 15px;
      box-sizing: border-box;
    }

    .image-error-context {
      position: absolute;
      text-align: center;
      width: 100%;
      top: -50px;

      .image-upload-max-count-error {
        padding: 10px 15px;
        border: 1px solid darken($error, 10%);
        color: #fff;
        background: $error;
        height: 40px;
        box-sizing: border-box;
        border-radius: 3px;
        cursor: default;
        display: inline-block;
      }
    }
  }

  .upload__image-wrapper {
    white-space: nowrap;
    overflow: auto;
    height: 318px;

    .image-no-items {
      border: 1px solid $gold-color;
      color: $gold-color;
      text-align: center;
      display: block;
      padding: 15px 0;
      width: 50%;
      margin: 108px auto 160px auto !important;
      height: 50px;
      box-sizing: border-box;
      line-height: 20px;
    }

    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 3px;
      border: 1px solid $gold-color;
    }

    &::-webkit-scrollbar
    {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb
    {
      border-radius: 3px;
      background-color: $gold-color;
      cursor: pointer;
    }
  }

  .image-item {
    position: relative;
    width: initial;
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: top;

    &:last-child {
      margin: 0;
    }

    img {
      width: auto;
      height: 300px;
      margin: auto;
      display: block;
    }

    .order-container {
      position: absolute;
      bottom: 5px;
      right: 5px;

      .arrow-btn {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: $gold-color;
        border-radius: 3px;
        text-align: center;
        line-height: 25px;
        cursor: pointer;
        margin: 0 0 0 3px;
        border: 1px solid $gold-color;

        &:hover {
          border: 1px solid darken($gold-color, 10);
          background: darken($gold-color, 10);
        }

        &.disabled {
          filter: grayscale(100%);
          cursor: default;

          &:hover {
            border: 1px solid $gold-color;
            background: $gold-color;
          }
        }

        svg {
          color: #fff;
          text-shadow: -1px -1px 3px #020202,
          2px 2px 4px #1b1b1b;
        }
      }
    }

    .remove-image-button {
      width: 20px;
      height: 20px;
      background: $dark-red;
      position: absolute;
      border-radius: 3px;
      bottom: 5px;
      left: 5px;
      text-align: center;
      line-height: 22px;
      cursor: pointer;
      border: 1px solid $dark-red;

      &:hover {
        border: 1px solid darken($dark-red, 10);
        background: darken($dark-red, 10);
      }

      svg {
        color: #fff;
        text-shadow: -1px -1px 3px #020202,
        2px 2px 4px #1b1b1b;
      }

    }
  }
}