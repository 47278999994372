@import "../../assets/scss/colors";

.toogle-messenger {
  color: #fff;

  &:hover {
    color: $gold-color;
  }
}

#messenger-container {
  .messenger-no-conservations {
    font-size: 18px;
    color: $gold-color;
    text-align: center;
    margin: 50% 0 0 0;
    font-family: 'Oranienbaum', Georgia, 'Times New Roman', Times, serif;
    transform: translateY(-50%);
    cursor: default;
  }


  .message-box {
    h4 {
      border-radius: 3px 3px 0 0;
      background: #b4ab6e;
      color: #fff;
      padding: 3px 10px;
      font-size: 16px;
      margin: 0 -15px;
      position: relative;
      height: 23px;
      box-sizing: border-box;

      #button-close-messenger {
        background: #AF4C50;
        color: #fff;
        position: absolute;
        top: 2px;
        width: 40px;
        height: 17px;
        right: 18px;
        text-align: center;
        line-height: 15px;
        padding: 0 0 1px 0;
        border-radius: 3px;
        cursor: pointer;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        svg {
          line-height: 10px;
        }
      }
    }

    button {
      width: 100%;
      font-weight: 300;
      font-size: 14px;
    }

    ul.conservation-list {
      list-style: none;
      padding: 0;

      li.message-item {
        float: none;
      }

      li {
        input[type="radio"] {
          display: none;
        }

        input[type="radio"]:checked + label {
          border: 1px solid $gold-color;

          .count-unread-messages {
            display: none;
          }
        }

        &:hover {
          background-color: #3e4042;
        }

        label {
          border: 1px solid transparent;
          border-radius: 3px;
          color: #e4e6eb;
          font-size: 15px;
          padding: 15px 5px;
          display: block;
          cursor: pointer;
          margin: 3px 5px;

          .count-unread-messages {
            background: $gold-color;
            color: #fff;
            border-radius: 5px;
            padding: 3px 5px;
            float: right;
            font-size: 12px;
          }
        }
      }
    }

    .messages-context {

      /* width */
      ::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        border-radius: 3px;
        border: 1px solid $gold-color;
        overflow: hidden;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $gold-color;
        border-radius: 3px;
        margin: 0 3px;
        width: 10px;
        cursor: pointer;
      }

      ul.messages-list {
        position: absolute;
        list-style: none;
        max-height: 84%;
        overflow: auto;
        padding: 0 10px 0 0;
        margin: 20px 0 0 0;
        top: 0;
        bottom: 90px;

        li {
          width: 100%;
        }

        div {
          color: #e4e6eb;
          font-size: 13px;
          padding: 5px;
          background-color: #202020;
          border: 1px solid #535558;
          margin-top: 10px;
          box-sizing: border-box;
          max-width: 80%;
          border-radius: 3px;
          float: none;

          .timestamp {
            font-size: 10px;
            margin-top: 5px;
            padding-top: 3px;
            border-top: 1px solid #535558;
            display: block;
            color: #9e9e9e;

          }
        }

        div.own-message {
          margin-left: auto;
          background-color: #202020;
          border: 1px solid #535558;
        }

        div.new-message {
          display: block;
          text-align: center;
          background: transparent;
          border: none;
          max-width: 500px;
          overflow: hidden;
          margin: 10px auto 0 auto;
          cursor: default;

          .new-message-label {
            position: relative;
            display: inline-block;
            text-align: center;
            border-radius: 5px;
            border: 1px solid $gold-color;
            font-size: 10px;
            padding: 3px 20px;
            color: $gold-color;

            &:before {
              content: "";
              width: 500px;
              border-bottom: 1px solid $gold-color;
              position: absolute;
              left: -500px;
              top: 8px;
            }

            &:after {
              content: "";
              width: 500px;
              border-bottom: 1px solid $gold-color;
              position: absolute;
              right: -500px;
              top: 8px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .nav-wrap {
    #messenger-container {
      width: auto;
      left: 10px;
    }
  }
}