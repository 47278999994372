@import "../../assets/scss/colors";

.profile-error-message{
  text-align: center;
  padding: 25px;
  font-size: 18px;
  color: $gold-color;
  background: #202020;
  max-width: 970px;
  border: 1px solid $gold-color;
  margin: 30px auto;
}