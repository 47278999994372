@import "../../assets/scss/colors";

.nav-wrap {
	background: #000;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 900000;

	.nav-container {
		max-width: 1000px;
		margin: 0 auto;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
		.nav-title {
			float: left;
			color: #fff;
			font-family: 'Segoe UI', sans-serif;
			padding: 20px 8px;
			font-size: 24px;
			@media only screen and (max-width: 500px){
				padding: 20px 7px;
			}
		}
		.nav-numbers {
			float: left;
			font-size: 14px;
			padding: 26px 15px;
			width: 460px;
			text-align: right;
			@media only screen and (max-width: 975px){
				width: unset;
				text-align: center
			}
			@media only screen and (max-width: 502px){
				padding: 26px 2px;
			}
			.nav-number-item:nth-child(2){
				margin-left: 12px;
				@media only screen and (max-width: 500px){
					display: none;
				}
			}

			.nav-number-item:first-child a:nth-child(2) {
				margin-left: 6px;
			}

		}
		.nav-numbers.login-nav-numbers{
			width: 160px;
			text-align: left;
			padding: 25px 8px 18px 8px;
			@media only screen and (min-width: 768px) and (max-width: 1000px){
				display: none;
			}
			@media only screen and (max-width: 390px){
				display: none;
			}
			.nav-number-item:nth-child(2){
				margin-left: 0px;
				@media only screen and (max-width: 768px){
					display: none;
				}
			}
		}
		nav#main{
			.nav-desktop{
				float: right;
				padding: 0 8px;
				margin: 0;
			}
			ul {
                list-style: none;
                margin: 0;
                padding: 0;
				&:after {
					content: '';
					display: block;
					clear: both;
				}
				li {
					float: left;
					a {
                        text-shadow: 0 1px 0 rgba(255,255,255,0.25);
						display: block;
                        padding: 23.5px 15px;
                        text-transform: uppercase;
                        line-height: 19px;
                        &.active {
                            color: $gold-color;
                        }
					}
				}
			}
		}
	}
}

///messenger
#messenger-container {
	form.message-box {
		height: 100%;

		.conservation-list {
			display: inline-block;
			width: 30%;
			vertical-align: top;
			height: 95%;
			border-right: 1px solid $gold-color;
			margin: 0;
			padding: 10px 10px 0 10px;
		}

		.messages-context {
			position: relative;
			display: inline-block;
			width: calc(68% - 25px);
			margin: 0 0 0 15px;
			vertical-align: top;
			height: calc(100% - 23px);

			.message-input-context {
				position: absolute;
				bottom: 5px;
				right: 5px;
				left: 5px;

				button {
					margin: 0;
				}
			}
		}
	}
}


.nav-wrap{
	.toogle-messenger{
		font-size: 26px;
		cursor: pointer;
		z-index: 1000;
		float: right;
		position: relative;
		margin: 15px 0;

		#messages-unread {
			display: none;
			position: absolute;
			top: 10px;
			background: $gold-color;
			color: #fff;
			border-radius: 15px;
			padding: 3px 5px;
			font-size: 10px;
		}

		svg{
			border-radius: 50%;
			padding: 5px;
		}
	}
	#messenger-container{
		display:none;
		overflow: hidden;
		position: fixed;
		right: 10px;
		z-index: 100;
		width: 720px;
		box-sizing: border-box;
		padding: 0;
		top: 90px;
		background: #202020;
		bottom: 100px;
		box-shadow: 0 12px 28px 0 #00000033, 0 2px 4px 0 #00000033, inset 0 0 0 1px #00000033;
		background: rgba(0,0,0,0.9);
		border: 1px solid $gold-color;
		border-radius: 3px;
	}
	#messenger-container.view{
		display: block;
	}
}

///mobile
.nav-wrap {
	.nav-container {
		nav#main {
			.nav-desktop{
				@media only screen and (max-width: 768px){
					display: none;
				}
			}
			.nav-mobile{
				@media only screen and (max-width: 768px) {
					max-height: 340px;
					overflow-x: visible;
					border-top: 1px solid transparent;
					box-shadow: inset 0 1px 0;
					-webkit-overflow-scrolling: touch;
					float: unset;
					transition: 1s all;
					&:before,&:after{
						content: " ";
						display: table;
						clear: both;
					}
				}
				@media only screen and (min-width: 769px){
					display: none;
				}
				ul {
					@media only screen and (max-width: 768px) {
						&:before,&:after{
							content: " ";
							clear: both;
							display: table;
						}
					}
					li {
						@media only screen and (max-width: 768px) {
							display: block;
							float: unset;
						}
						a{
							@media only screen and (max-width: 768px) {
								padding-top: 10px;
								padding-bottom: 10px;
								line-height: 19px;
							}
						}
					}
				}
			}
		}
		.toogle-mobile-menu{
			@media only screen and (max-width: 768px) {
				display: block;
				@media only screen and (max-width: 500px){
					margin-right: 2px;
				}
			}
			@media only screen and (min-width: 769px) {
				display: none;
			}
			position: relative;
			float: right;
			margin-right: 15px;
			padding: 9px 10px;
			margin-top: 16px;
			margin-bottom: 16px;
			background-color: transparent;
			background-image: none;
			border: 1px solid transparent;
			border-radius: 0;
			.icon-bar{
				background: #fff;
				display: block;
				width: 22px;
				height: 2px;
				border-radius: 1px;
				&+.icon-bar{
					margin-top: 4px;
				}
			}
		}
	}
}