@import "../../assets/scss/colors";

.limitedHeight {
  height: 33px;
}

h1,
h2,
h3,
h4 {
  font-family: 'Segoe UI', sans-serif;
  font-weight: normal;
  line-height: 1.1;
  text-transform: uppercase;
  position: relative;

  .header-icon {
    vertical-align: top;
    margin: 0 10px 0 0;
    font-size: 30px;
  }

  .header-label {
    line-height: 33px;
  }

  &.center {
    text-align: center;
  }

  &.caseSensitive {
    text-transform: initial;
  }
}

h1 {
  font-size: 160px;
  @media only screen and (max-width: 500px) {
    font-size: 74px;
  }
  @media only screen and (min-width: 501px) and (max-width: 780px) {
    font-size: 120px;
  }
  color: #ffffff;
  line-height: 1em;
  margin: 0;

  div {
    display: block;
    font-size: 30px;
    line-height: 1em;
    margin: 0;
  }
}

h3 {
  line-height: 1em;
  margin: 0;
  font-size: 31px;
  color: #ffffff;
}

h5 {
  margin: 20px 0 0;
  text-transform: uppercase;
  font-size: 17px;
  line-height: 1.1;
  color: #ffffff;
  font-weight: 400;

  &.center {
    text-align: center;
  }

  &.caseSensitive {
    text-transform: initial;
  }
}

h2 {
  color: #ffffff;
  font-size: 40px;
  margin: 80px 0 0;
}

h4 {
  color: $gold-color;
  margin: 0;
  font-size: 21px;
  text-transform: none;
}

h4.profileform {
  margin-top: 30px;
}

span.portalName {
  display: inline-block;

  &.portalName1 {
    text-transform: initial;
  }

  &.portalName2 {
    color: $gold-color;
  }
}
