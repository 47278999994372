@import "../../assets/scss/colors";

footer {
	width: 100%;
	background-color: #000;
	padding: 20px 0;

	.footer-wrapper {
		max-width: 920px;
		margin: auto;
		div {
			text-align: center;
			span {
				display: inline-block;
				margin: 0 20px;
			}
		}

		.footer-links {
			text-align: center;
			li {
				display: inline-block;
				margin: 0 4px;
			}
		}

		.copyright {
			font-family: 'PT Sans', Helvetica, Arial, sans-serif;
			font-size: 13px;
			line-height: 1.5384;
			color: #888;
		}
	}
}
