@import "./assets/scss/colors";

html, body, body #root {
    height: 100%;
}

body #root .wrap {
    min-height: calc(100% - 100px);
    margin: 71px 0 0 0;
}

.Toastify {
    position: relative;
    z-index: 99999999;
}

.ck-balloon-panel {
    z-index: 900000 !important;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

html, body {
    background: $background-color;
    //background-image: url('/assets/img/texture.png');
    background-image: url('/assets/img/slide1.jpg');
    background-attachment:fixed;
    background-size: cover;
    font-size: 13px;
    font-family: "Roboto";
    margin: 0;
    padding:0;
    .mobile-home{
        display: none;
    }
    @media only screen and (max-width: 768px) {
        a{
            text-decoration: underline;
        }
        .mobile-home{
            display: block;
            padding-left: 15px !important;
        }
    }
    .filterContainer{
        label{
            background: transparent;
            background-color: transparent;
            border: 1px solid #b4ab6e;
            border-radius: 0;
            color: #ffffff;
            cursor: pointer;
            font-family: 'Segoe UI', sans-serif;
            font-size: 16px;
            line-height: 1.33;
            margin-bottom: 30px;
            margin-right: 5px;
            margin-left: 5px;
            padding: 10px 10px 10px 10px;
            text-align: center;
            text-shadow: 0 1px 0 #ffffff;
            text-transform: uppercase;
            display: block;
        }
        input:checked + label{
            background-color: #b4ab6e;
        }
        input{
            display: none;
        }
    }
    p{
        >a{
            text-decoration: underline;
        }
    }
    .text{
        p{
            a{
                text-decoration: underline;
            }
        }
    }
    .d-none{
        display: none;
    }
    .cursorPointer{
        cursor: pointer;
    }
    .sliderButton3{
        border: 3px solid #b4ab6e;
        padding: 8px 20px 8px 30px;
    }
}

body {
    overflow-x: hidden;
}

a {
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    color: #fff;
    text-decoration: none;
    background: transparent;

    &:hover {
        color: $gold-color;
        outline: 0;
    }
}

.fileContainer {
    background: transparent !important;
}

html{
    .Toastify__toast--success{
        background: $background-color;
        border: 1px solid $gold-color;
        .Toastify__progress-bar{
            background-color: $gold-color;
        }
    }
    .Toastify__toast--error {
        background: $background-color;
        color: #CF6679;
        border: 1px solid #CF6679;
        .Toastify__progress-bar{
            background-color: #CF6679;
        }
    }
    .justifyConentCenter{
        justify-content: center;
    }
    .whatsapplink{
        display: inline-flex;
        .waicon{
            margin-right: 5px;
            display: inline-flex;
            align-items: center;
        }
    }
    p{
        >a{
            text-decoration: underline;
        }
    }
    .text{
        p{
            a{
                text-decoration: underline;
            }
        }
    }
    .d-none{
        display: none;
    }
    .cursorPointer{
        cursor: pointer;
    }
    .button-flex-center{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}