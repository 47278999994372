@import "../../assets/scss/colors";

.media-gallery {
  overflow: auto;
  font-size: 0;
  //white-space: nowrap;
  padding: 0 0 5px;
  columns: 3 200px;
  column-gap: 1rem;

  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 3px;
    border: 1px solid $gold-color;
  }

  &::-webkit-scrollbar
  {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: 3px;
    background-color: $gold-color;
    cursor: pointer;
  }

  * {
    font-size: 16px;
  }

  .media-gallery-item {
    //display: inline-flex;
    border: 1px solid transparent;
    cursor: pointer;
    //margin: 0 2px 4px 2px;
    position: relative;
    width: calc(100% - 2px) !important;
    @media only screen and (min-width: 501px) and (max-width: 768px) {
      width: calc(100% - 2px) !important;
    }
    @media only screen and (max-width: 500px) {
      width: calc(100% - 2px) !important;
    }

    .gallery-play-video-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      color: $gold-color;

    }

    &:hover {
      border: 1px solid $gold-color;
    }
    img{
      width: 100% !important;
      height: unset !important;
    }
  }

  .gallery-view-container {
    position: fixed;
    z-index: 9000;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .gallery-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.5);
      border: 1px solid $gold-color;
      padding: 0 15px;
      box-sizing: border-box;
      border-radius: 3px;

      .video-container {
        position: relative;
      }

      .gallery-counter {
        color: $gold-color;
        cursor: default;
        line-height: 30px;
      }

      .prev-media, .next-media {
        position: absolute;
        top: 50%;
        z-index: 123;
        font-size: 40px;
        transform: translate(0, -50%);
        color: $gold-color;
        cursor: pointer;

        &:hover {
          color: $dark-gold-color;
        }
      }

      .prev-media {
        left: 0;
        background: #0000009c;
      }

      .next-media {
        right: 0;
        background: #0000009c;
      }

      img {
        display: flex;
        height: calc(100% - 80px);
      }

      .header-box, .action-box {
        padding: 6px 0 0 0;
        text-align: center;
        height: 40px;
        box-sizing: border-box;
      }

      .action-box {
        .close-icon {
          color: $gold-color;
          cursor: pointer;
          border: 1px solid $gold-color;
          padding: 3px 6px;
          border-radius: 5px;
          display: inline-block;

          &:hover {
            color: $dark-gold-color;
            border: 1px solid $dark-gold-color;
          }
        }
      }

      .play-video-icon {
        cursor: pointer;
        position: absolute;
        width: 100px;
        height: 100px;
        border: solid 2px $gold-color;
        border-radius: 100px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .play-icon {
          color: $gold-color;
          position: absolute;
          top: 28px;
          left: 35px;
          font-size: 43px;
        }

        &:before {
          content: "";
          width: 34px;
          height: 53px;
          border-left: 2px solid $gold-color;
          border-radius: 5px;
          position: absolute;
          top: 23px;
          left: 37px;
        }

        &:after {
          content: "";
          position: absolute;
          top: 27px;
          left: 19px;
          width: 42px;
          height: 42px;
          transform: rotate(45deg) skew(-5deg, -5deg);
          border-top: 2px solid $gold-color;
          border-right: 2px solid $gold-color;
          border-radius: 5px;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }

    }
  }
}

@media only screen and (max-width: 800px){
  .gallery-container {
    img{
      object-fit: cover;
    }
  }

  .media-gallery-item-image {
    width: 100% !important;
    height: auto !important;
  }
}