@import "../../assets/scss/colors";

textarea {
  display: block;
  width: calc(100% - 24px);
  height: 100px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.5384;
  background-color: #000;
  background-image: none;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  margin-top: 0;
  max-width: calc(100% - 24px);
  font-family: "Roboto";
  color: #888;
  border: 1px solid #222;

  &::-webkit-input-placeholder {
    color: #444;
  }

  &:-ms-input-placeholder {
    color: #444;
  }

  &::placeholder{
    color: #444;
  }

  &:focus {
    border-color: $gold-color;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(128, 113, 84, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(128, 113, 84, 0.6);
  }
}

/* textarea.profileform{
  font-family: "PT Sans",Helvetica,Arial,sans-serif;
  font-size: 13px;
  line-height: 1.5384;
  color: #888;
  display: block;
  width: 100%;
  max-width: 423px;
  padding: 0;
  background-color: transparent;
  background-image: none;
  border: none;
  border-bottom: 1px solid #888;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
} */