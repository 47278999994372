.text-color-white, .text-color-white p {
  color: #fff !important;
}

.modify-able-page-content-container {
  color: #b4ab6e;

  p {
    margin: 0;
  }

  &.edit-mode {
    max-width: 1024px;
    margin: 16px auto;
    text-align: left;
  }

  * {
    color: #b4ab6e
  }

  .ck-editor__editable {
    max-height: 300px;
    overflow: auto;
  }

  .optional {
    font-size: 12px;
    margin: 0 0 0 8px;
  }

  .description {
    margin: 0 0 4px 2px ;
  }

  button.save {
    margin: 16px 0 0 0;
  }

  button.cancel {
    margin: 16px 0 0 0;
    float: right;
  }

  .edit {
    margin-top: 5px;
    vertical-align: top;
    cursor: pointer;
    line-height: 13px;
    font-size: 13px;
    font-weight: normal;

    svg {
      font-size: 13px;
    }

    span {
      vertical-align: top;
      margin: 0 0 0 5px;
      font-size: 13px;
      text-transform: none;
    }

    &:hover {
      color: darken(#b4ab6e, 10%);
      text-decoration: underline;

      * {
        color: darken(#b4ab6e, 10%);
      }
    }
  }
}