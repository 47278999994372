$gold-color: #b4ab6e;
$gold-gradient: to bottom, #b4ab6e 0, #716b44 100%;
$dark-gold-color: #716b44 ;

$old-gold-color: #807154;
$old-gold-gradient: to bottom, #807154 0, #5b503c 100%;

$background-color: #444444;

$dark-red: #914949;
$error: $dark-red;
$red-gradient: to bottom, #914949 0, #914949 100%;
$dark-red-hover: #7b3e3e;
$backgroundColorRGBA: rgba(0,0,0,0.5);
