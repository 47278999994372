@import "./../../assets/scss/colors";

.out-off-screen {
  .react-draggable {
    left: -5000px !important;
  }
}

.in-screen {
  .react-draggable {
    left: calc(50% - 325px);
    top: 100px;
    z-index: 999999 !important;
  }
}

.react-draggable {
  width: 650px !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

  .drag-able-container {
    background: rgba(0,0,0,0.9);
    border: 1px solid $gold-color;
    border-radius: 3px;
    overflow: hidden;

    .headline {
      background: $gold-color;
      color: #fff;
      padding: 3px 10px;
      font-size: 16px;
    }

    .content {
      padding: 10px;
    }
  }
}