@import "../../assets/scss/colors";

.sliderbutton {
  background: transparent;
  background-color: transparent;
  border: 1px solid $gold-color;
  border-radius: 0;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Segoe UI', sans-serif;
  font-size: 17px;
  letter-spacing: 10px;
  line-height: 1.33;
  margin-top: 30px;
  padding: 10px 20px 10px 30px;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  text-transform: uppercase;
}