@import "../../assets/scss/colors";

.icontext {
    color: #fff;
    position: relative;
    padding-left: 20px;
    svg {
        color: $gold-color;
        font-size: 15px;
        position: absolute;
        left:0;
    }
}