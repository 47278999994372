@import "../../assets/scss/colors";

/*
input[type="text"], input[type="password"], input[type="number"] {
    display: block;
    width: calc(100% - 24px);
    height: 33px;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.5384;
    background-color: #000;
    background-image: none;
    border-radius: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    color: #888;
    border: 1px solid #222;

  &::-webkit-input-placeholder {
    color: #444;
  }

  &:-ms-input-placeholder {
    color: #444;
  }

  &::placeholder{
    color: #444;
  }

  &:focus {
        border-color: $gold-color;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(128, 113, 84, 0.6);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(128, 113, 84, 0.6);
    }
    &.margin {
        margin-top: 12px;
    }
    &.error {
        border-color: #ab4343;
    }
}

 input[type="text"].profileform, input[type="password"].profileform, input[type="number"].profileform {
    //font-family: "PT Sans",Helvetica,Arial,sans-serif;
    font-size: 13px;
    line-height: 1.5384;
    //color: #888;
    display: block;
    height: unset;
    //width: calc(100% - 24px);
    //padding: 0;
    //background-color: transparent;
    //background-image: none;
    //border: none;
    //border-bottom: 1px solid #888;
    //border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}*/

.MuiTextField-root{
  width: 100%;
  label{
    color: #fff;
    left: 12px;
    z-index: 1;
    cursor: text;
  }

  label.Mui-focused {
    cursor: default;
  }

  input{
    display: block;
    width: calc(100% - 0px);
    height: 33px;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.5384;
    background-color: #000;
    box-sizing: border-box;
    background-image: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    color: $gold-color;
    border: 1px solid #222;
    background: rgba(0,0,0,0.4);
    background-color: rgba(0,0,0,0.4) !important;
    border-radius: 2px;
    margin: 0 0 5px 0;

    &:not(:focus) {
      border: 1px solid #444;
    }

    &:hover {
      border: 1px solid #b4ab6e;
    }

    &::-webkit-input-placeholder {
      color: #444;
    }

    &:-ms-input-placeholder {
      color: #444;
    }

    &::placeholder{
      color: #444;
    }
    &:focus {
      border-color: $gold-color;
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(128, 113, 84, 0.6);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(128, 113, 84, 0.6);
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="time"]::-webkit-calendar-picker-indicator{
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  &.margin {
    margin-top: 5px;
  }
  &.error {
    input{
      border-color: #ab4343;
    }
  }
}

.MuiTextField-root{
  .MuiInput-underline{
    &:after, &:before{
      display: none;
    }
  }
  input[type="number"]{
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.MuiFormLabel-root.Mui-focused{
  color: #444 !important;
}

.error-text {
  color: #ab4343;
}


p.MuiFormHelperText-root {
  display: none;

  &.Mui-focused, &.Mui-error {
    color: $gold-color;
    display: block;
  }
}
