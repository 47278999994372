@import "../../assets/scss/colors";

.legal-age-modal-container {
  z-index: 999999 !important;
}

.legal-age-modal {
  position: absolute;
  width: 500px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  border: 1px solid $gold-color;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 15px 25px;
  color: $gold-color;
  text-align: center;

  .headline {
    margin: 0 0 15px 0;
  }

  .modal-action-bar {
    padding: 25px 0 0 0;
  }

  button, .btn-link {
    margin: 0 5px;
  }
}

.legal-age-container {
  +.wrap{
    > .data-container {
      filter: blur(3px);
    }
  }
}

@media (max-width: 768px) {
  .legal-age-modal  {
    width: auto;
    left: 10px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    overflow: scroll;

    button {
      width: 100%;
      margin: 15px 0 0 0;
    }
  }
}