h2, h4 {
  margin: 0;
}

.MuiTextField-root {
  input {
    background: rgba(0,0,0,0.6);
  }
}

@media only screen and (min-width: 769px) {
  .makeStyles-mobilepadding-8{
    padding: 24px 5px;
  }
}


@media only screen and (max-width: 769px) {
  html .MuiContainer-root{
    padding: 24px 5px;
    margin: 5px 0 0;
    width: calc(100% - 10px);
  }
}