@import "../../assets/scss/colors";

.teaser-container {
  color: $gold-color;
  text-align: justify;

  .collapse-button {
    border: 1px solid $gold-color;
    color: $gold-color;
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px 0 0 0;
    display: inline-block;

    &:hover {
      background: $dark-gold-color;
      color: #fff;
    }
  }
}